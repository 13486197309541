import React from "react";
import "./BackButton.scss";

const BackButton = ({ text, onClick }) => {
  return (
    <div className="BackButton">
      <button onClick={onClick}>{text}</button>
    </div>
  );
};

export default BackButton;
