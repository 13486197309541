import React from "react";
import "./Ticket.scss";
import { FiCalendar, FiHome, FiMapPin, FiVideo, FiCheck } from "react-icons/fi";
import dayjs from "dayjs";
import { isSecondType } from "../../../helpers/isSecondType";
import { SERVICE_TYPES } from "../../../enums/serviceTypes";
import { calculateSqareMeterPrice } from "../../../helpers/calculateSqareMeterPrice";
import { FaCheckCircle } from "react-icons/fa";

const Ticket = (props) => {
  const displayedServiceText = () => {
    if (props.choosenService === SERVICE_TYPES.Hemstädning) {
      return "Kostnadsfritt hembesök";
    } else if (props.choosenService === SERVICE_TYPES.Flyttstädning) {
      return SERVICE_TYPES.Flyttstädning;
    } else if (props.choosenService === SERVICE_TYPES.Storstädning) {
      return SERVICE_TYPES.Storstädning;
    } else {
      return "Kostnadsfritt besök";
    }
  };

  let basePrice = calculateSqareMeterPrice(
    props.placeSize,
    props.choosenService
  );
  const mulitplyPrice =
    props.choosenService === SERVICE_TYPES.Flyttstädning ? 0.12 : 0.33;
  const fourSideBalconPrice = mulitplyPrice * basePrice;
  const twoSideBalconPrice = 0.2 * basePrice;

  return (
    <div className="Ticket">
      <h1>Sammanfattning</h1>
      <div className="flex">
        <p>Typ av tjänst:</p>
        <h4>{displayedServiceText()}</h4>
      </div>
      <div className="flex">
        <p>Antal kvadratmeter:</p>
        <h4>{props.placeSize}</h4>
      </div>
      <div className="line" />
      {/* service type HERE */}
      {props.meetingType &&
        (props.meetingType === "home" ? (
          <div className="meetingType">
            <FiHome />
            <p>Personligt hembesök</p>
          </div>
        ) : (
          <div className="meetingType">
            <FiVideo />
            <p>Digitalt hembesök</p>
          </div>
        ))}
      {props.date && (
        <div className="meetingType" style={{ marginTop: 18 }}>
          <FiCalendar />
          <p>
            {dayjs(props.date).format("D MMM")} {props.time}{" "}
            {props.time && "kl."}
          </p>
        </div>
      )}
      {props.step === 4 && (
        <div className="meetingType" style={{ marginTop: 18 }}>
          <FiMapPin />
          <p>
            {props.address && props.address + ","} {props.postNumber}{" "}
            {props.ort}
          </p>
        </div>
      )}
      {(props.meetingType || props.date) && <div className="line" />}
      {isSecondType(props.choosenService) &&
        (props.additions["Inglasad balkong/ uterum"] > 0 ||
          props.additions["Extra ugnsplåtar"] > 0 ||
          props.fourSideBalcon ||
          props.twoSideBalcon) && (
          <div className="ticketAdditions">
            <h4>Tillval</h4>
            {props.fourSideBalcon && (
              <div className="flex">
                <div>
                  <FiCheck />
                  <p>4-sidig fönsterputs</p>
                </div>
                <h5>{parseInt(fourSideBalconPrice)} kr</h5>
              </div>
            )}
            {props.twoSideBalcon && (
              <div className="flex">
                <div>
                  <FiCheck />
                  <p>2-sidig fönsterputs</p>
                </div>
                <h5>{parseInt(twoSideBalconPrice)} kr</h5>
              </div>
            )}
            {props.additions["Inglasad balkong/ uterum"] > 0 && (
              <div className="flex">
                <div>
                  <FiCheck />
                  <p>
                    {props.additions["Inglasad balkong/ uterum"]}x Inglasad
                    balkong/ uterum
                  </p>
                </div>
                <h5>{props.additions["Inglasad balkong/ uterum"] * 450} kr</h5>
              </div>
            )}
            {props.additions["Extra ugnsplåtar"] > 0 && (
              <div className="flex">
                <div>
                  <FiCheck />
                  <p>{props.additions["Extra ugnsplåtar"]}x Extra ugnsplåtar</p>
                </div>
                <h5>{props.additions["Extra ugnsplåtar"] * 90} kr</h5>
              </div>
            )}
            <div className="line" />
          </div>
        )}
      {isSecondType(props.choosenService) && (
        <div className="guarantee">
          <div className="flex">
            <div className="rectangle" />
            <FaCheckCircle />
            <p>
              {props.choosenService === SERVICE_TYPES.Storstädning ? "2" : "5"}{" "}
              dagars kvalitetsgaranti ingår.
            </p>
          </div>
        </div>
      )}

      {/*  */}
      <div className="price">
        <div className="price-flex">
          <h2>TOTALT</h2>
          <h2>{parseInt(props.price)} kr</h2>
        </div>
        {isSecondType(props.choosenService) && (
          <>
            <p>efter RUT-avdrag</p>
            <hr />
            <p>
              Material och framkörning är inkluderat i priset. Inga dolda eller
              extra avgifter tillkommer.{" "}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Ticket;
