import React from "react";
import {
	IoHeartOutline,
	AiOutlineCarryOut,
	AiOutlineUser,
	IoRocketOutline,
	IoLeafOutline,
	AiOutlineSmile,
	AiOutlineSafety,
} from "react-icons/all";
import { SERVICE_TYPES } from "../../enums/serviceTypes";

const AdvantagesSection = ({ text1, text2, text3, text4, type }) => {
	return (
		<div class="py-12 bg-white">
			<div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
				<dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-8">
					<div>
						<dt>
							<div class="flex items-center justify-center h-12 w-12 rounded-full light-green-bg text-green-600">
								<IoHeartOutline size={23} />
							</div>
							<p class="mt-5 text-lg leading-6 font-bold text-gray-900">
								{text1.heading}
							</p>
						</dt>
						<dd class="mt-2 text-base text-gray-500">{text1.description}</dd>
					</div>

					<div>
						<dt>
							<div class="flex items-center justify-center h-12 w-12 rounded-full light-green-bg text-green-600">
								{type === SERVICE_TYPES.Storstädning ||
									type === SERVICE_TYPES.Flyttstädning ? (
									<AiOutlineSmile size={23} />
								) : (
									<AiOutlineCarryOut size={23} />
								)}
							</div>
							<p class="mt-5 text-lg leading-6 font-bold text-gray-900">
								{text2.heading}
							</p>
						</dt>
						<dd class="mt-2 text-base text-gray-500">{text2.description}</dd>
					</div>

					<div>
						<dt>
							<div class="flex items-center justify-center h-12 w-12 rounded-full light-green-bg text-green-600">
								{type === SERVICE_TYPES.Storstädning ||
									type === SERVICE_TYPES.Flyttstädning ? (
									<AiOutlineSafety size={23} />
								) : (
									<AiOutlineUser size={23} />
								)}
							</div>
							<p class="mt-5 text-lg leading-6 font-bold text-gray-900">
								{text3.heading}
							</p>
						</dt>
						<dd class="mt-2 text-base text-gray-500">{text3.description}</dd>
					</div>
					<div>
						<dt>
							<div class="flex items-center justify-center h-12 w-12 rounded-full light-green-bg text-green-600">
								{type === "Index" && <IoRocketOutline size={23} />}
								{(type === SERVICE_TYPES.Hemstädning ||
									type === SERVICE_TYPES.Flyttstädning ||
									SERVICE_TYPES.Storstädning) &&
									type !== "Index" &&
									type !== SERVICE_TYPES.Kontorsstädning && (
										<IoLeafOutline size={23} />
									)}
								{type === SERVICE_TYPES.Kontorsstädning && (
									<AiOutlineSafety size={23} />
								)}
							</div>
							<p class="mt-5 text-lg leading-6 font-bold text-gray-900">
								{text4.heading}
							</p>
						</dt>
						<dd class="mt-2 text-base text-gray-500">{text4.description}</dd>
					</div>
				</dl>
			</div>
		</div>
	);
};

export default AdvantagesSection;
