import React from "react";
import { FiHome, FiVideo } from "react-icons/fi";
import "./Step2RegularBtn.scss";

const Step2RegularBtn = ({ meetingType, type, onClick, text, description }) => {
  return (
    <button
      className={"Step2RegularBtn" + (meetingType === type ? " active" : "")}
      onClick={onClick}
    >
      <div className="flex">
        {type === "digital" ? <FiVideo /> : <FiHome />}
        <h4>{text}</h4>
      </div>
      <p>{description}</p>
    </button>
  );
};

export default Step2RegularBtn;
