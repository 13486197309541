import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { isSecondType } from "../../../helpers/isSecondType";
import FormInput from "../../FormInput/FormInput";
import BackButton from "../BackButton/BackButton";
import SubmitButton from "../SubmitButton/SubmitButton";
import { withPrefix } from "gatsby";
import "./Step4.scss";

const Step4 = (props) => {
	return (
		<>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					props.onSubmit();
				}}
			>
				<div className="Step4 white-shadow">
					<h1 className="heading">Snart klart, sen ses vi! </h1>
					<p className="description">
						Nu behöver vi bara några sista uppgifter från dig.
					</p>
					<div className="flex">
						<FormInput
							placeholder="Förnamn"
							value={props.firstName}
							onChange={(val) => props.setFirstName(val)}
						/>
						<FormInput
							placeholder="Efternamn"
							value={props.lastName}
							onChange={(val) => props.setLastName(val)}
						/>
					</div>
					<div className="flex">
						<FormInput
							placeholder="Mailadress"
							value={props.email}
							onChange={(val) => props.setEmail(val)}
						/>
						<FormInput
							placeholder="Mobilnummer"
							type="tel"
							value={props.mobileNumber}
							onChange={(val) => props.setMobileNumber(val)}
						/>
					</div>
					<div className="flex">
						<FormInput
							placeholder="Gatuadress"
							value={props.address}
							onChange={(val) => props.setAddress(val)}
						/>
					</div>
					<div className="flex">
						<FormInput
							placeholder="Postnummer"
							value={props.postNumber}
							onChange={(val) => props.setPostNumber(val)}
						/>
						<FormInput
							placeholder="Ort"
							value={props.ort}
							onChange={(val) => props.setOrt(val)}
						/>
					</div>

					{isSecondType(props.choosenService) && (
						<div className="flex">
							<FormInput
								placeholder="Personnummer"
								value={props.personNumber}
								onChange={(val) => props.setPersonNumber(val)}
							/>
						</div>
					)}
					{isSecondType(props.choosenService) && (
						<div className="flex flex-info first">
							<AiOutlineInfoCircle />
							<p className="info">
								{`Svenska skattebetalare har rätt till 50% rabatt på hushållsarbete. Ange ditt personnummer - och njut av RUT-avdrag direkt! `}
								<a
									target="_blanko"
									href="https://www.skatteverket.se/privat/fastigheterochbostad/rotochrutarbete/villkorforattfarutavdrag.4.3810a01c150939e893f2b9d.html"
								>
									Läs mer.
								</a>
							</p>
						</div>
					)}
					<div className="flex">
						<FormInput
							textarea
							placeholder="Meddelande till Dreamclean"
							value={props.notes}
							onChange={(val) => props.setNotes(val)}
						/>
					</div>
					{isSecondType(props.choosenService) && (
						<div className="flex flex-info">
							<AiOutlineInfoCircle />
							<p className="info">
								Meddela oss om du har känsliga möbler eller material.
							</p>
						</div>
					)}
				</div>
				<div className="confirm-info white-shadow">
					{isSecondType(props.choosenService) ? (
						<p className="description" style={{ margin: 0 }}>
							Genom att klicka på “Bekräfta & boka” godkänner jag Dreamcleans{" "}
							<a
								rel="noopener noreferrer"
								href={props.emailCitySufix !== '' ? withPrefix(
									"/Allmänna Villkor - Servicetillfällen Linköping.docx.pdf"
								) : withPrefix(
									"/Allmänna Villkor - Servicetillfällen.docx.pdf"
								)}
								target="_blanko"
							>
								villkor
							</a>{" "}
							och{" "}
							<a href={`${props.emailCitySufix}/integritets-och-cookiespolicy`} target="_blanko" rel="noopener noreferrer">
								integritetspolicy.
							</a>
						</p>
					) : (
						<p className="description" style={{ margin: 0 }}>
							Genom att klicka på “Bekräfta & boka” godkänner jag Dreamcleans{" "}
							<a href={`${props.emailCitySufix}/integritets-och-cookiespolicy`} target="_blanko" rel="noopener noreferrer">
								integritetspolicy.
							</a>
						</p>
					)}
				</div>
				<div className="buttons-flex">
					<BackButton text="Tillbaka" onClick={props.onBack} />
					<SubmitButton
						text="Bekräfta & boka"
						isValid={true}
					// onClick={onSubmit}
					/>
				</div>
			</form>
		</>
	);
};

export default Step4;
