import axios from "axios";
import dayjs from "dayjs";

const API_URL = "https://api.dreamclean.nu/api/dreamclean/send-email";

export const sendHemstadningEmail = (
	callback,
	meetingType,
	placeSize,
	postNumber,
	date,
	time,
	howOften,
	firstName,
	lastName,
	email,
	mobileNumber,
	address,
	ort,
	notes,
	sufix
) => {
	axios
		.post(`${API_URL}${sufix}`, {
			subject: `Hemstädning - ${dayjs(date).format("DD.MM.YYYY")}`,
			html: `<div>
				<h2>New Hemstädning Service for ${dayjs(date).format("DD.MM.YYYY")}</h2>
				<p>Meeting type: ${meetingType}</p>
				<p>Place size: ${placeSize}</p>
				<p>Date: ${dayjs(date).format("DD.MM.YYYY")}</p>
				<p>Time: ${time}</p>
				<p>How often cleaning: ${howOften}</p>
				<p>First name: ${firstName}</p>
				<p>Last name: ${lastName}</p>
				<p>Email: ${email}</p>
				<p>Mobile number: ${mobileNumber}</p>
				<p>Address: ${address}</p>
				<p>Post number: ${postNumber}</p>
				<p>Ort: ${ort}</p>
				<p>Notes: ${notes}</p>
			</div> `,
		})
		.then((response) => {
			callback();
		});
};

export const sendKontorsstadningEmail = (
	callback,
	meetingType,
	placeSize,
	postNumber,
	date,
	time,
	howOften,
	firstName,
	lastName,
	email,
	mobileNumber,
	address,
	ort,
	notes,
	sufix
) => {
	axios
		.post(`${API_URL}${sufix}`, {
			subject: `Kontorsstädning -  ${dayjs(date).format("DD.MM.YYYY")}`,
			html: `<div>
				<h2>New Kontorsstädning Service for ${dayjs(date).format("DD.MM.YYYY")}</h2>
				<p>Meeting type: ${meetingType}</p>
				<p>Place size: ${placeSize}</p>
				<p>Date: ${dayjs(date).format("DD.MM.YYYY")}</p>
				<p>Time: ${time}</p>
				<p>How often cleaning: ${howOften}</p>
				<p>First name: ${firstName}</p>
				<p>Last name: ${lastName}</p>
				<p>Email: ${email}</p>
				<p>Mobile number: ${mobileNumber}</p>
				<p>Address: ${address}</p>
				<p>Post number: ${postNumber}</p>
				<p>Ort: ${ort}</p>
				<p>Notes: ${notes}</p>
			</div> `,
		})
		.then((response) => {
			callback();
		});
};

export const sendFltyystadningEmail = (
	callback,
	placeSize,
	postNumber,
	fourSigitBalcon,
	inglasadBalkonug,
	extraUgnsplatar,
	date,
	leftKeyInOffice,
	firstName,
	lastName,
	email,
	mobileNumber,
	address,
	ort,
	personNumber,
	notes,
	price,
	sufix
) => {
	axios
		.post(`${API_URL}${sufix}`, {
			subject: `Flyttstädning -  ${dayjs(date).format("DD.MM.YYYY")}`,
			html: `<div>
				<h2>New Flyttstädning Service for ${dayjs(date).format("DD.MM.YYYY")}</h2>
				<p>Place size: ${placeSize}</p>
				<p>Date: ${dayjs(date).format("DD.MM.YYYY")}</p>
				<p>4-sidig fönsterputs: ${fourSigitBalcon ? "Yes" : "No"}</p>
				<p>Inglasad balkong/uterum: ${inglasadBalkonug}</p>
				<p>Extra ugnsplåtar: ${extraUgnsplatar}</p>
				<p>Left key in office? : ${leftKeyInOffice ? "Yes" : "No"}</p>
				<p>First name: ${firstName}</p>
				<p>Last name: ${lastName}</p>
				<p>Email: ${email}</p>
				<p>Mobile number: ${mobileNumber}</p>
				<p>Person number: ${personNumber}</p>
				<p>Address: ${address}</p>
				<p>Post number: ${postNumber}</p>
				<p>Ort: ${ort}</p>
				<p>Notes: ${notes}</p>
				<h3>Price: ${price}</h3>
			</div> `,
		})
		.then((response) => {
			callback();
		});
};

export const sendStorstadningEmail = (
	callback,
	placeSize,
	postNumber,
	fourSigitBalcon,
	twoSigitBalcon,
	inglasadBalkonug,
	extraUgnsplatar,
	date,
	leftKeyInOffice,
	firstName,
	lastName,
	email,
	mobileNumber,
	address,
	ort,
	personNumber,
	notes,
	price,
	sufix
) => {
	axios
		.post(`${API_URL}${sufix}`, {
			subject: `Storstädning -  ${dayjs(date).format("DD.MM.YYYY")}`,
			html: `<div>
				<h2>New Storstädning Service for ${dayjs(date).format("DD.MM.YYYY")}</h2>
				<p>Place size: ${placeSize}</p>
				<p>Date: ${dayjs(date).format("DD.MM.YYYY")}</p>
				<p>4-sidig fönsterputs: ${fourSigitBalcon ? "Yes" : "No"}</p>
				<p>2-sidig fönsterputs: ${twoSigitBalcon ? "Yes" : "No"}</p>
				<p>Inglasad balkong/uterum: ${inglasadBalkonug}</p>
				<p>Extra ugnsplåtar: ${extraUgnsplatar}</p>
				<p>Left key in office? : ${leftKeyInOffice ? "Yes" : "No"}</p>
				<p>First name: ${firstName}</p>
				<p>Last name: ${lastName}</p>
				<p>Email: ${email}</p>
				<p>Mobile number: ${mobileNumber}</p>
				<p>Person number: ${personNumber}</p>
				<p>Address: ${address}</p>
				<p>Post number: ${postNumber}</p>
				<p>Ort: ${ort}</p>
				<p>Notes: ${notes}</p>
				<h3>Price: ${price}</h3>
			</div> `,
		})
		.then((response) => {
			callback();
		});
};
