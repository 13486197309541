import React from "react";
import "./ExpandButtons.scss";

const ExpandButtons = ({ expand }) => {
  return (
    <div className={"ExpandButton" + (expand ? " active" : "")}>
      {expand ? "KLAR" : "ÄNDRA"}
    </div>
  );
};

export default ExpandButtons;
