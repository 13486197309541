export const minimumPrice = 2673;
export const biggerPlaceSqarePrice = 29;

export const FlyttstadningSqareMeters = {
  51: 2673,
  52: 2673,
  53: 2673,
  54: 2673,
  55: 2673,
  56: 2673,
  57: 2673,
  58: 2673,
  59: 2673,
  60: 2673,
  61: 2708.4,
  62: 2746.6,
  63: 2784.6,
  64: 2822.4,
  65: 2860,
  66: 2897.4,
  67: 2934.6,
  68: 2971.6,
  69: 3008.4,
  70: 3045,
  71: 3081.4,
  72: 3117.6,
  73: 3153.6,
  74: 3189.4,
  75: 3225,
  76: 3260.4,
  77: 3295.6,
  78: 3330.6,
  79: 3365.4,
  80: 3400,
  81: 3434.4,
  82: 3468.6,
  83: 3502.6,
  84: 3536.4,
  85: 3570,
  86: 3603.4,
  87: 3636.4,
  88: 3669.6,
  89: 3702.4,
  90: 3735,
  91: 3767.4,
  92: 3799.6,
  93: 3831.6,
  94: 3863,
  95: 3895,
  96: 3926,
  97: 3957,
  98: 3988,
  99: 4019,
  100: 4050,
  101: 4080,
  102: 4110,
  103: 4140,
  104: 4170,
  105: 4200,
  106: 4229,
  107: 4258,
  108: 4287,
  109: 4316,
  110: 4345,
  111: 4373,
  112: 4401,
  113: 4429,
  114: 4457,
  115: 4485,
  116: 4512,
  117: 4539,
  118: 4566,
  119: 4593,
  120: 4620,
  121: 4646,
  122: 4672,
  123: 4689,
  124: 4724,
  125: 4750,
  126: 4775,
  127: 4800,
  129: 4825,
  130: 4875,
  131: 4899,
  132: 4923,
  133: 4947,
  134: 4971,
  135: 4995,
  136: 5018,
  137: 5041,
  138: 5064,
  139: 5087,
  140: 5110,
  141: 5132,
  142: 5154,
  143: 5176,
  144: 5198,
  145: 5220,
  146: 5241,
  147: 5262,
  148: 5283,
  149: 5304,
  150: 5325,
  151: 5345,
  152: 5365,
  153: 5385,
  154: 5405,
  155: 5425,
  156: 5444,
  157: 5463,
  158: 5482,
  159: 5501,
  160: 5520,
  161: 5538,
  162: 5556,
  163: 5574,
  164: 5592,
  165: 5610,
  166: 5672,
  167: 5644,
  168: 5661,
  169: 5678,
  170: 5695,
  171: 5711,
  172: 5727,
  173: 5743,
  174: 5759,
  175: 5775,
  176: 5790,
  177: 5805,
  178: 5820,
  179: 5835,
  180: 5850,
  181: 5864,
  182: 5878,
  183: 5892,
  184: 5906,
  185: 5920,
  186: 5933,
  187: 5946,
  188: 5959,
  189: 5972,
  190: 5985,
  191: 5997,
  192: 6009,
  193: 6021,
  194: 6033,
  195: 6045,
  196: 6056,
  197: 6067,
  198: 6078,
  199: 6089,
  200: 6100,
  201: 6110,
  202: 6120,
  203: 6130,
  204: 6140,
  205: 6150,
  206: 6159,
  207: 6168,
  208: 6177,
  209: 6186,
  210: 6195,
  211: 6203,
  212: 6211,
  213: 6219,
  214: 6227,
  215: 6235,
};
