import React, { useState } from "react";
import "./Step2PremiumBtn.scss";
import { BiRectangle } from "react-icons/bi";
import { RiCheckboxMultipleBlankLine } from "react-icons/ri";
import { FaRegHandPeace } from "react-icons/fa";
import { VscWindow } from "react-icons/vsc";
import { STEP2_BTNS } from "../../../enums/stepsEnum";
import ExpandButtons from "../ExpandButtons/ExpandButtons";
import Balcon from "../../../assets/images/icons/Balcon2.svg";

const Step2PremiumBtn = ({
  additions,
  setAdditions,
  quantity,
  increaseQuantity,
  decreaseQuantity,
  type,
  description,
  onClick,
  expand,
  active,
}) => {
  const [expandActive, setExpandActive] = useState(false);

  const handleClick = () => {
    if (expand) {
      setExpandActive((p) => !p);
    } else {
      onClick(type);
    }
  };

  return (
    <button
      className={
        "Step2PremiumBtn" +
        (expand && expandActive ? " active" : "") +
        (active ? " active" : "") +
        (expandActive || (active && expand) ? " expand" : "")
      }
      onClick={handleClick}
    >
      <div className="flex">
        <div>
          <h4>
            {quantity > 0 && `${quantity}x`} {type}
          </h4>
          <p>{description}</p>
        </div>
        <div
          className={
            "icon-wrapper" +
            (expandActive || (active && expand) ? " expand" : "")
          }
        >
          {expandActive && expand && <ExpandButtons expand />}
          {!expandActive && active && expand && <ExpandButtons />}
          {type === STEP2_BTNS["Nej, tack!"].title && <FaRegHandPeace />}
          {type === STEP2_BTNS["Inglasad balkong/ uterum"].title &&
            !expandActive &&
            !active && <Balcon />}
          {type === STEP2_BTNS["4-sidig fönsterputs"].title && (
            <RiCheckboxMultipleBlankLine />
          )}
          {type === STEP2_BTNS["2-sidig fönsterputs"].title && <VscWindow />}
          {type === STEP2_BTNS["Extra ugnsplåtar"].title &&
            !expandActive &&
            !active && <BiRectangle />}
        </div>
      </div>
      {expandActive && (
        <div className="flex" style={{ marginTop: 25 }}>
          <h5>Antal</h5>
          <div className="quantity">
            <button className="rectangle" onClick={decreaseQuantity}>
              -
            </button>
            <div className="rectangle">{quantity}</div>
            <button className="rectangle" onClick={increaseQuantity}>
              +
            </button>
          </div>
        </div>
      )}
    </button>
  );
};

export default Step2PremiumBtn;
