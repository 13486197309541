import React from "react";
import { FiChevronRight } from "react-icons/fi";
import recommended from "../../../assets/images/2021Icon.png";
import { SERVICE_TYPES } from "../../../enums/serviceTypes";
import { Link } from "gatsby";

const ChooseService = ({ setChoosenService, emailCitySufix }) => {
	// const handleServiceClick = (service) => {
	// 	setChoosenService(service);
	// };
	return (
		<>
			<div className="mobile-first-flex">
				<h1>{emailCitySufix === '' ? 'Välkommen till en enklare vardag!' : 'Städhjälp i Linköping'}</h1>
				<a href="https://www.reco.se/dreamclean-sweden-ab" target="_blanko">
					<img src={recommended} className="recommended" alt="Recommended" />
				</a>
			</div>
			<p>
				{emailCitySufix === '' ? `Beräkna ditt pris och boka städning direkt online. Smidigt, tryggt och
				enkelt!` : 'Räkna ut ditt pris och boka städning enkelt online. Att ha städning hemma ska vara smidigt, tryggt och säkert.'}
			</p>
			<h3>{emailCitySufix === '' ? 'Välj tjänst för att beräkna ditt pris:' : 'Välj tjänst för att räkna ut ditt pris:'}</h3>
			<hr />
			<div className="flex">
				<Link to={`${emailCitySufix}/tjanster/hemstadning`} >
					{SERVICE_TYPES.Hemstädning}
					<FiChevronRight />
				</Link>
				<Link to={`${emailCitySufix}/tjanster/flyttstadning`} >
					{SERVICE_TYPES.Flyttstädning}
					<FiChevronRight />
				</Link>
			</div>
			<div className="flex">
				<Link to={`${emailCitySufix}/tjanster/storstadning`}>
					{SERVICE_TYPES.Storstädning}
					<FiChevronRight />
				</Link>
				<Link
					to={`${emailCitySufix}/tjanster/kontorsstadning`}
				>
					{SERVICE_TYPES.Kontorsstädning}
					<FiChevronRight />
				</Link>
			</div>
			{/* <div className="row">
        <div className="col">
          <BsCheck />
          <p>Ingen bindningstid</p>
        </div>
        <div className="col">
          <BsStar />
          <p>100% nöjd-kund garanti</p>
        </div>
        <div className="col">
          <RiLeafLine />
          <p>Svanemärkta rengöringsmedel</p>
        </div>
      </div> */}
		</>
	);
};

export default ChooseService;
