import React from "react";
import "./SubmitButton.scss";

const SubmitButton = ({ isValid, text, onClick, mobile, closeTicket, checkIsLastStepValid }) => {

  console.log(isValid)
  return (
    <div
      className={
        "SubmitButton" +
        (mobile ? " mobile" : "") +
        (closeTicket ? " closeTicket" : "")
      }
    >
      <button
        disabled={!isValid}
        onClick={() => isValid && onClick && onClick()}
        type="submit"
      >
        {text}
      </button>
    </div>
  );
};

export default SubmitButton;
