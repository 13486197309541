import { SERVICE_TYPES } from "../enums/serviceTypes";
import {
  biggerPlaceSqarePrice,
  FlyttstadningSqareMeters,
  minimumPrice,
} from "./prices";

export const calculateSqareMeterPrice = (size, type) => {
  if (type === SERVICE_TYPES.Flyttstädning) {
    if (size <= 50) {
      return minimumPrice;
    }
    if (size > 215) {
      return size * biggerPlaceSqarePrice;
    }
    return FlyttstadningSqareMeters[size];
  } else if (type === SERVICE_TYPES.Storstädning) {
    if (size <= 50) {
      return minimumPrice * 0.75;
    }
    if (size > 215) {
      return size * biggerPlaceSqarePrice * 0.75;
    }
    return FlyttstadningSqareMeters[size] * 0.75;
  }
};
