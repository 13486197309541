import React, { useState } from "react";
import "./Step2.scss";
import SubmitButton from "../SubmitButton/SubmitButton";
import { STEP2, STEP2_BTNS } from "../../../enums/stepsEnum";
import Step2RegularBtn from "../Step2RegularBtn/Step2RegularBtn";
import Step2PremiumBtn from "../Step2PremiumBtn/Step2PremiumBtn";
import { isSecondType } from "../../../helpers/isSecondType";
import { calculateSqareMeterPrice } from "../../../helpers/calculateSqareMeterPrice";
import { isMobile } from "react-device-detect";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Step2Popup from "../Step2Popup/Step2Popup";
import { SERVICE_TYPES } from "../../../enums/serviceTypes";
import BackButton from "../BackButton/BackButton";

const Step2 = ({
	meetingType,
	setMeetingType,
	onSubmit,
	choosenService,
	additions,
	setAdditions,
	fourSideBalcon,
	setFourSideBalcon,
	twoSideBalcon,
	setTwoSideBalcon,
	price,
	setPrice,
	placeSize,
	onBack
}) => {
	const [popupVisible, setPopupVisible] = useState(false);

	return (
		<>
			{popupVisible && (
				<Step2Popup
					choosenService={choosenService}
					onClose={() => setPopupVisible(false)}
					active={popupVisible}
				/>
			)}
			<div className="Step2 white-shadow">
				<h1 className="heading">{STEP2[choosenService].heading}</h1>
				<p className="description">{STEP2[choosenService].description}</p>
				{isMobile && isSecondType(choosenService) && (
					<div
						className="mobile-first"
						onClick={() => setPopupVisible(true)}
						aria-hidden="true"
					>
						<AiOutlineInfoCircle />
						<p>Vad ingår i städningen?</p>
					</div>
				)}
				{isSecondType(choosenService) ? (
					<div className="buttons" style={{ marginTop: 32 }}>
						<Step2PremiumBtn
							type={STEP2_BTNS["Nej, tack!"].title}
							description={STEP2_BTNS["Nej, tack!"].description}
							additions={additions}
							setAdditions={setAdditions}
							onClick={() => {
								onSubmit();
								setAdditions({
									"Inglasad balkong/ uterum": 0,
									"Extra ugnsplåtar": 0,
								});
								setFourSideBalcon(false);
								let basePrice = calculateSqareMeterPrice(
									placeSize,
									choosenService
								);
								setPrice(basePrice);
							}}
						/>
						{choosenService === SERVICE_TYPES.Storstädning && (
							<Step2PremiumBtn
								type={STEP2_BTNS["2-sidig fönsterputs"].title}
								description={STEP2_BTNS["2-sidig fönsterputs"].description}
								additions={additions}
								setAdditions={setAdditions}
								onClick={() => {
									let basePrice = calculateSqareMeterPrice(
										placeSize,
										choosenService
									);
									const mulitplyPrice =
										choosenService === SERVICE_TYPES.Flyttstädning
											? 0.12
											: 0.33;
									const fourSideBalconPrice = mulitplyPrice * basePrice;
									const twoSideBalconPrice = 0.2 * basePrice;
									if (twoSideBalcon) {
										setTwoSideBalcon(false);
										setPrice((p) => p - twoSideBalconPrice);
									} else {
										setTwoSideBalcon(true);
										if (fourSideBalcon) {
											setFourSideBalcon(false);
											setPrice((p) => p - fourSideBalconPrice);
										}
										setPrice((p) => p + twoSideBalconPrice);
									}
								}}
								active={twoSideBalcon}
							/>
						)}
						<Step2PremiumBtn
							type={STEP2_BTNS["4-sidig fönsterputs"].title}
							description={STEP2_BTNS["4-sidig fönsterputs"].description}
							additions={additions}
							setAdditions={setAdditions}
							onClick={() => {
								let basePrice = calculateSqareMeterPrice(
									placeSize,
									choosenService
								);
								const mulitplyPrice =
									choosenService === SERVICE_TYPES.Flyttstädning ? 0.12 : 0.33;
								const fourSideBalconPrice = mulitplyPrice * basePrice;
								const twoSideBalconPrice = 0.2 * basePrice;
								if (fourSideBalcon) {
									setFourSideBalcon(false);
									setPrice((p) => p - fourSideBalconPrice);
								} else {
									setFourSideBalcon(true);
									if (choosenService === SERVICE_TYPES.Storstädning) {
										if (twoSideBalcon) {
											setTwoSideBalcon(false);
											setPrice((p) => p - twoSideBalconPrice);
										}
									}
									setPrice((p) => p + fourSideBalconPrice);
								}
							}}
							active={fourSideBalcon}
						/>
						<Step2PremiumBtn
							type={STEP2_BTNS["Inglasad balkong/ uterum"].title}
							description={STEP2_BTNS["Inglasad balkong/ uterum"].description}
							additions={additions}
							setAdditions={setAdditions}
							expand
							active={additions["Inglasad balkong/ uterum"] > 0}
							quantity={additions["Inglasad balkong/ uterum"]}
							increaseQuantity={(e) => {
								e.stopPropagation();
								setAdditions((p) => ({
									...p,
									"Inglasad balkong/ uterum": p["Inglasad balkong/ uterum"] + 1,
								}));
								setPrice((p) => p + 450);
							}}
							decreaseQuantity={(e) => {
								e.stopPropagation();
								if (additions["Inglasad balkong/ uterum"] > 0) {
									setAdditions((p) => ({
										...p,
										"Inglasad balkong/ uterum":
											p["Inglasad balkong/ uterum"] - 1,
									}));
									setPrice((p) => p - 450);
								}
							}}
						/>
						<Step2PremiumBtn
							type={STEP2_BTNS["Extra ugnsplåtar"].title}
							description={STEP2_BTNS["Extra ugnsplåtar"].description}
							additions={additions}
							setAdditions={setAdditions}
							expand
							active={additions["Extra ugnsplåtar"] > 0}
							quantity={additions["Extra ugnsplåtar"]}
							increaseQuantity={(e) => {
								e.stopPropagation();
								setAdditions((p) => ({
									...p,
									"Extra ugnsplåtar": p["Extra ugnsplåtar"] + 1,
								}));
								setPrice((p) => p + 90);
							}}
							decreaseQuantity={(e) => {
								e.stopPropagation();
								if (additions["Extra ugnsplåtar"] > 0) {
									setAdditions((p) => ({
										...p,
										"Extra ugnsplåtar": p["Extra ugnsplåtar"] - 1,
									}));
									setPrice((p) => p - 90);
								}
							}}
						/>
					</div>
				) : (
					<div className="buttons">
						<Step2RegularBtn
							text="Personligt hembesök"
							description={STEP2[choosenService].homeBtn}
							type="home"
							onClick={() => setMeetingType("home")}
							meetingType={meetingType}
						/>
						<Step2RegularBtn
							text="Digitalt hembesök"
							description={STEP2[choosenService].digitalBtn}
							type="digital"
							onClick={() => setMeetingType("digital")}
							meetingType={meetingType}
						/>
					</div>
				)}
			</div>
			<div className="buttons-flex">
				<BackButton text="Tillbaka" onClick={onBack} />
				<SubmitButton
					isValid={isSecondType(choosenService) ? true : meetingType !== ""}
					text="Välj datum"
					onClick={onSubmit}
				/>
			</div>
		</>
	);
};

export default Step2;
